@import "./Button.scss";
@import "./Socials.scss";
@import "./Table.scss";

#auth {
    color: #4f5152;

    #shadow {
        padding: 12% 15% !important;

        @media all and (min-width: 678px) {
            box-shadow: 0px 0px 10px #ccc;
        }

        .logo {
            max-width: 250px;
            width: 100%;
        }
    }
}

#dashboard {
    padding: 2% !important;

    @media all and (min-width: 678px) {
        box-shadow: 0px 0px 10px #ccc;
    }

    .content-contratos {
        .error {
            border: solid 1px #ff0000;
        }

        &__detalle {
            .cabecera {
                background-color: #627171;
                padding: 25px;
                font-size: 18px;
                color: #fff;
                margin: 30px 0;
                cursor: pointer;
                position: relative;

                .btn {
                    position: absolute;
                    top: 16px;
                    right: 5px;

                    img {
                        width: 25px;
                        transform: rotate(0deg);
                    }
                }
            }
        }

        .open-collapse {
            .icon-action-accordeon {
                transform: rotate(45deg) !important;
            }
        }

        .total {
            background-color: #F2F2F2;
            color: #627171;
            padding: 30px;
            margin: 20px 0;
            font-weight: 400 !important;
            font-size: 26px;
            text-align: center;
        }

        .checkout {
            p {
                margin: 10px 0 !important;
            }

            .email {
                margin: 10px 0 !important;
                background-color: #f1f1f1;
            }
        }

        .contact {
            .content_info {
                h5 {
                    color: #627171;
                    font-weight: bold !important;
                }

                img {
                    max-width: 45%;
                }
            }
        }

        .btn-verde {
            margin: 10px 0;
            padding: 10px 10%;
        }
    }

}

p {
    color: #4f5152;
    font-weight: 300;
}

h4,
h2 {
    color: #4f5152 !important;
    font-weight: 500 !important;
    text-align: center;
    font-size: 24px !important;
}

.spinner {
    margin: 30px 0;
}


.detalle {
    margin-top: 30px;
    margin-bottom: 30px;

    .text-title-detalle {
        margin: 10px 0 !important;

        h2 {
            margin: 0;
            font-size: 24px;
            font-weight: bold !important;
            line-height: 33px;
        }
    }

    .mis-datos {
        font-weight: bold !important;
        font-size: 18px !important;
    }


    .back-detalle {
        background-color: #f2f2f2 !important;
        padding: 30px;
        th,
        td {
            font-size: 16px !important;
            color: #627171 !important;
        }
    }
}

a {
    text-decoration: none !important;

    .phone {
        font-size: 32px;
        font-weight: bold;
        color: #811e6c;
        letter-spacing: 1px;
    }
}

.mis-datos {
    font-size: 22px !important;
    margin-bottom: 10px !important;
    color: #627171 !important;
    strong {
        font-weight: 400;
    }
}

.text-title-detalle {
    margin: 10px 0 !important;

    h2 {
        margin: 0;
        font-size: 28px;
        text-align: center;
        font-weight: 700 !important;
    }
}

.title-detalle {
    background-color: #f2f2f2;
    padding: 15px 75px;
    margin: 10px 0 30px;
    display: inline-block;
    text-align: center;

    h2 {
        margin: 0;
        font-size: 28px;
        text-align: center;
        font-weight: 700 !important;
    }
}

.img_logo_sacra {
    height: 200px !important;
    float: left !important;
}
.row-content-cabecera {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: solid 1px #f1f1f1;

    strong {
        color: #811e6c;
    }

    .text-left {
        
        h4 {
            float: left;
        }
    }
    .text-right {
        a {
            float: right;
        }
    }

    .logo-sendero {
        width: 100%;
    }

    .content-redes {
        margin-top: 35px;
        float: right;
        p {
            vertical-align: middle !important;
            align-self: center;
            margin: 0;
            color: #811e6c;
            font-weight: bold !important;
            font-size: 1.4em;
            margin-right: 10px;
            display: inline-block;
            margin-top: -25px;
        }

        .content-icons {
            display: inline-block;
            margin-top: 5px;
            a {
                color: #007bff;
                text-decoration: none;
                background-color: transparent;
            }

            img {
                margin: 5px;

                &:hover {
                    -webkit-transition: all .15s ease;
                    transition: all .15s ease;
                    -webkit-transform: translateY(-5px);
                    transform: translateY(-5px);
                }
            }
        }
    }
}

.table-detalle {
    width: 100%;
    margin: 20px 0;

    th {
        text-align: inherit;
        background-color: #811e6c !important;
        color: #ffffff;
        text-align: center;
        padding: 10px 0;
    }

    td {
        text-align: center;
    }
}
.btn-verde {
    background-color: #811e6c !important;
    color: #ffffff;
}

@media (max-width: 678px){

    .content-redes {
        text-align: center;
        width: 65%;
        margin: 0 auto;
        float: none !important;
        margin-top: 0px !important;
    }
    .img_logo_sacra {
        height: 250px !important;
        float: none !important;
        margin: 0 auto;
    }
    .row-content-cabecera {
        font-size: 12px !important;
        border-bottom: solid 1px #f1f1f1;
        text-align: center;
        .text-left {
            h4 {
                float: none;
                font-size: 12px !important;
            }
        }
        .text-right {
            a {
                float: none;
            }
        }
        h2 {
            font-size: 14px !important;
        }
    }
}

